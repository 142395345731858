// Local storage keys

export const LOCAL_STORAGE_KEYS = {};

export const SESSION_STORAGE_KEYS = {
  PREV_ROUTES: 'prevRoutes'
};

// Request headers

export const TOKEN_KEYS = {
  REFRESH_TOKEN: 'refresh_jwt_token',
  ACCESS_TOKEN: 'access_jwt_token'
};
export const HEADERS_KEYS = {
  REFRESH_TOKEN: 'refreshToken',
  REFRESH_TOKEN_REAL: 'refreshTokenReal'
};
// React-query keys
export const QUERY_KEYS = {
  USER: 'USER',
  CHECK: 'CHECK',
  AUTH: 'AUTH',
  PROFILE_FOLLOWERS_ALL: 'PROFILE_FOLLOWERS_ALL',
  PROFILE_FOLLOWING_ALL: 'PROFILE_FOLLOWING_ALL',
  PROFILE_UPDATE: 'PROFILE_UPDATE',
  LINKEDIN_PROFILE: 'LINKEDIN_PROFILE',
  SECTORS: 'SECTORS',
  SUB_SECTORS: 'SUB_SECTORS',
  REGIONS: 'REGIONS',
  MEMO_REGIONS: 'MEMO_REGIONS',
  COMPANIES: 'COMPANIES',
  LOCATION_REGION: 'LOCATION_REGION',
  LOCATION: 'LOCATION',
  PROFILE: 'PROFILE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  MUTUAL_ASSOCIATION: 'MUTUAL_ASSOCIATION',
  CONNECTION_BY_ID: 'CONNECTION_BY_ID',
  CHART: 'CHART',
  QUESTION_SECTORS: 'QUESTION_SECTORS',
  QUESTION_COMPANIES: 'QUESTION_COMPANIES',
  QUESTION_QUERIES: 'QUESTION_QUERIES',
  CURRENT_STATUS: 'CURRENT_STATUS',
  PREVIOUS_ENDEAVOURS: 'PREVIOUS_ENDEAVOURS',
  POST: 'POST',
  FILTERS: 'FILTERS',
  POST_BY_ID: 'POST_BY_ID',
  POST_DATA: 'POST_DATA',
  POST_PROFILE_DATA: 'POST_PROFILE_DATA',
  POST_TREAD_DATA: 'POST_TREAD_DATA',
  POST_TREAD_DATA_REPLIES: 'POST_TREAD_DATA_REPLIES',
  POST_TREAD_DATA_IMAGES: 'POST_TREAD_DATA_IMAGES',
  POST_TREAD_DATA_STATUS: 'POST_TREAD_DATA_STATUS',
  QUOTE_POSTS_BY_POST_ID: 'QUOTE_POSTS_BY_POST_ID',
  POST_HOME_DATA_ALL: 'POST_HOME_DATA_ALL',
  POST_HOME_DATA_FOLLOWING: 'POST_HOME_DATA_FOLLOWING',
  STATISTIC: 'STATISTIC',
  POST_REPLY: 'POST_REPLY',
  POST_OWN_REPLY: 'POST_OWN_REPLY',
  SENTIMENT: 'SENTIMENT',
  CONNECTION: 'CONNECTION',
  CACHED_CONNECTION: 'CACHED_CONNECTION',
  TRENDING_MUSINGS: 'TRENDING_MUSINGS',
  LATEST_IDEAS: 'LATEST_IDEAS',
  QUOTE_REPOST_VIEW: 'QUOTE_REPOST_VIEW',
  SENTIMENT_SWINGS: 'SENTIMENT_SWINGS',
  LIKE: 'LIKE',
  POST_LIKES: 'POST_LIKES',
  POST_PRAISES: 'POST_PRAISES',
  POST_CLAPS: 'POST_CLAPS',
  POST_REPOSTS: 'POST_REPOSTS',
  POST_QUOTE_POSTS: 'POST_QUOTE_POSTS',
  QUESTION_ANSWER: 'QUESTION_ANSWER',
  WORK_HISTORY: 'WORK_HISTORY',
  IDEAS: 'IDEAS',
  VISIBILITY_SETTINGS: 'VISIBILITY_SETTINGS',
  NEW_NOTIFICATIONS: 'NEW_NOTIFICATIONS',
  OLD_NOTIFICATIONS: 'OLD_NOTIFICATIONS',
  PERMISSIONS: 'PERMISSIONS',
  SEARCH_PAGE_STATE: 'SEARCH_PAGE_STATE',
  SEARCH_IN_MODAL: 'SEARCH_IN_MODAL',
  BASIC_POST_INFO: 'BASIC_POST_INFO',
  MEMO: 'MEMO',
  MEMOS_ON_PROFILE: 'MEMOS_ON_PROFILE',
  CREATE_MEMO: 'CREATE_MEMO',
  UPDATE_MEMO: 'UPDATE_MEMO',
  GET_MEMO: 'GET_MEMO',
  RATE_MEMO: 'RATE_MEMO',
  GET_MY_RATE: 'GET_MY_RATE',
  GET_MY_RATE_DATA: 'GET_MY_RATE_DATA',

  GET_MEMO_LIST: 'GET_MEMO_LIST',
  GET_USER_DRAFTS: 'GET_USER_DRAFTS',

  MEMOS_OUTHER_USER: 'MEMOS_OUTHER_USER',
  MEMOS_CURRENT_USER: 'MEMOS_CURRENT_USER',
  PUBLISHED_MEMO: 'PUBLISHED_MEMO',
  MEMOS_BY_ID: 'MEMOS_BY_ID',
  MEMOS_BY_ID_DATA: 'MEMOS_BY_ID_DATA',
  MEMOS_POST_BY_ID: 'MEMOS_POST_BY_ID',
  MEMOS_FILTERS: 'MEMOS_FILTERS',

  LINK_PREVIEW: 'LINK_PREVIEW',
  REVIEW_LINK_PREVIEW: 'REVIEW_LINK_PREVIEW',

  CHANNELS: 'CHANNELS',
  CHANNELS_STATE: 'CHANNELS_STATE',
  CHANNELS_STATE_USER_INFO: 'CHANNELS_STATE_USER_INFO',
  ADORATIONS: 'ADORATIONS',
  GET_USER_BOOKMARKS: 'GET_USER_BOOKMARKS',
  MESSAGES_BASIC_INFO: 'MESSAGES_BASIC_INFO',
  CREATE_WAITLIST_ITEM: 'CREATE_WAITLIST_ITEM',

  GET_INVITATION: 'GET_INVITATION',
  SET_INVITATION: 'SET_INVITATION',

  NEWSFEEDS: 'NEWSFEEDS',
  NEWSFEEDS_FILTERS: 'NEWSFEEDS_FILTERS',
  GET_PDF_BASE64: 'GET_PDF_BASE64',

  GET_LIST_TAGS: 'GET_LIST_TAGS',
  LIST_TAGS: 'LIST_TAGS',

  GET_NEWS_ALLOWED_FILTERS: 'GET_NEWS_ALLOWED_FILTERS',
  GET_NEWS_ALLOWED_FILTERS_DATA: 'GET_NEWS_ALLOWED_FILTERS_DATA',

  SET_CURRENT_FILTER: 'SET_CURRENT_FILTER',

  IS_ANON_USER: 'IS_ANON_USER',
  IS_NON_FINANCE: 'IS_NON_FINANCE',

  HIDE_RIGHT_TOOLBAR: 'HIDE_RIGHT_TOOLBAR',
  GET_HIDE_RIGHT_TOOLBAR: 'GET_HIDE_RIGHT_TOOLBAR',

  GET_DEBATE_DETAILS: 'GET_DEBATE_DETAILS',

  COMPANY_MARKET_DATA: 'COMPANY_MARKET_DATA',

  POLL_QUERIES: 'POLL_QUERIES',
  POLL_SENTIMENT_SWINGS: 'POLL_SENTIMENT_SWINGS',
  GET_POLL_DETAILS: 'GET_DEBATE_DETAILS',
  GET_ALL_POLL_FILTER: 'GET_ALL_POLL_FILTER',
  GET_PRESELECTED_POLL_FILTER: 'GET_PRESELECTED_POLL_FILTER',
  GET_AVAILABLE_POLL_FILTER: 'GET_AVAILABLE_POLL_FILTER'
};

export const ROUTER_KEYS = {
  ROOT: '/',
  AUTH: '/auth',
  WAITLIST: '/waitlist',
  PULL: '/pull',
  HOME: '/home',
  MEMO: '/idea',
  NOTIFICATIONS: '/notifications',
  MESSAGES: '/messages',
  BOOKMARKS: '/bookmarks',
  ONBOARDING: '/onboarding',
  ONBOARDING_WELCOME: '/onboarding-welcome',
  RECOVER_PASSWORD: '/recover-password',
  PROFILE: '/profile',
  VISIBILITY_SETTINGS: '/settings',
  SEARCH_PAGE: '/search-page',
  MEMO_PAGE: '/idea',
  MEMO_ADD: '/idea/idea-add',
  MEMO_ADD_UPLOADED: '/idea/idea-add-by-document',
  MEMO_PREVIEW: '/idea/preview-idea',
  MEMO_PUBLISH: '/idea/publish-idea',
  EDIT_PROFILE: '/edit-profile',
  INVITATION: '/invitation',
  NEWSFEED_PAGE: '/newsfeed',
  POLLS: '/polls',
  PDF_VIEW: '/pdf-view'
};

export const TAB_FIRST_WORD = 'Villan • ';

export const TAB_PAGES = {
  WAITLIST: `${TAB_FIRST_WORD}Waitlist`,
  HOME: `${TAB_FIRST_WORD}Home`,
  PROFILE: `${TAB_FIRST_WORD}Profile`,
  EDIT_PROFILE: `${TAB_FIRST_WORD}Edit Profile`,
  SEARCH: `${TAB_FIRST_WORD}Search`,
  NOTIFICATIONS: `${TAB_FIRST_WORD}Notifications`,
  INBOX: `${TAB_FIRST_WORD}Inbox`,
  MEMOS: `${TAB_FIRST_WORD}Ideas`,
  BOOKMARKS: `${TAB_FIRST_WORD}Bookmarks`,
  NEWS: `${TAB_FIRST_WORD}News`,
  INVITATIONS: `${TAB_FIRST_WORD}Invitations`,
  SETTINGS: `${TAB_FIRST_WORD}Settings`,
  ONBOARDING: `${TAB_FIRST_WORD}Onboarding`,
  LOGIN: `${TAB_FIRST_WORD}Login`,
  PRIVACY_POLICY: `${TAB_FIRST_WORD}Privacy Policy`,
  RECOVER_PASSWORD: `${TAB_FIRST_WORD}Recover Password`,
  TEARMS: `${TAB_FIRST_WORD}Terms of Service`,
  POLLS: `${TAB_FIRST_WORD}Polls`
};
